import { registerSW } from "../sw-registration";

Promise.all([import("@/Root"), import("@/App")]).then(
    ([{ default: render }, { default: App }]) => {
        render(App);
    },
);
registerSW();

export {};
